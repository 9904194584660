import * as React from 'react';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import Button from '../Button';
import Minus from '../../assets/icons/minus.svg';
import Plus from '../../assets/icons/plus.svg';
import useBasket from '../../hooks/useBasket';
import {
  Card,
  WrapperLink,
  Notification,
  Image,
  Desc,
  Title,
  Price,
  TitleIngredients,
  SelectOption,
  WrapperAmount,
  Amount,
  ButtonAmount,
  Input,
} from './styled';

export default function CardDish({
  dish,
  delivery,
  totalPrice,
  setDishes,
  setNewTotalPrice,
  amountDish,
  setAmountDish,
  animation,
}) {
  const { to } = useBasket();
  const [amount, setAmount] = useState(1);
  const [price, setPrice] = useState(0);
  const refCard = useRef(null);
  const {
    id,
    posterId,
    header,
    animationPhoto: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: {
            images: {
              fallback: { src },
            },
          },
        },
      },
    },
    photo,
    price: defaultPrice,
    description,
    options,
    isPremium,
    isNew,
    weight,
    type,
  } = dish;
  const [activeOption, setActiveOption] = useState(
    options[0]?.food_option || null,
  );
  const [activePoster, setActivePoster] = useState(
    posterId || options[0]?.posterId || null,
  );
  const { localFile } = Array.isArray(photo) ? photo[0] : photo;
  const {
    data: { description: descriptionDish },
  } = description;

  const onChangeSelect = ({ target: { value } }) => {
    const [priceOption, option, activeId] = value.split('/');

    setActiveOption(option);
    setActivePoster(activeId);
    setPrice(+priceOption);
  };

  const dropDownOptions = !defaultPrice && (
    <SelectOption onChange={(event) => onChangeSelect(event)}>
      {options.map(
        ({ posterId: activeId, price: priceOption, food_option: option }) => (
          <option key={activeId} value={`${priceOption}/${option}/${activeId}`}>
            {option}
          </option>
        ),
      )}
    </SelectOption>
  );

  const getPrice = () => {
    if (!defaultPrice && price === 0) {
      return options[0].price;
    }

    if (price > 0) {
      return price;
    }

    return defaultPrice;
  };

  const createAnimation = () => {
    const fly = document.createElement('div');
    const { width, height } = refCard.current.getBoundingClientRect();

    fly.classList.add('flyNode');

    refCard.current.appendChild(fly);

    fly.style.left = `${width / 2}px`;
    fly.style.top = `${height / 2}px`;
    const { x: xFly, y: yFly } = fly?.getBoundingClientRect();

    fly.style.left = `${to.x - xFly + width / 2}px`;
    fly.style.top = `${to.y - yFly + height / 2}px`;
    fly.style.opacity = '0';
    fly.style.width = '50px';
    fly.style.height = '50px';
    fly.style.transform = 'scale(0.6)';
    setTimeout(() => fly.remove(), 2000);
  };

  const onDelivery = () => {
    let match = false;
    const copyDish = { ...dish };
    const resDelivery = delivery.map((item) => {
      if (!item.activeOption) {
        item.activeOption = null;
        item.optionPrice = null;
      }

      if (!item.posterId) {
        item.posterId = activePoster;
      }

      if (item.id === copyDish.id && item.activeOption === activeOption) {
        item.amount += +amount;
        match = true;

        return item;
      }

      return item;
    });

    if (!match) {
      copyDish.amount = +amount;
      copyDish.activeOption = activeOption;
      copyDish.posterId = activePoster;
      copyDish.optionPrice = +getPrice();
      resDelivery.push(copyDish);
    }

    setAmount(1);
    setDishes(() => [...resDelivery]);
    setNewTotalPrice(() => +totalPrice + +getPrice() * +amount);
    setAmountDish(() => +amountDish + +amount);

    if (animation) {
      createAnimation();
    }
  };

  const getNotification = () => {
    if (isPremium) {
      return <Notification type="premium">Premium</Notification>;
    }

    if (isNew) {
      return <Notification type="new">New</Notification>;
    }
  };

  return (
    <Card ref={refCard} src={src}>
      <WrapperLink to={`/${type}/product/${id}`}>
        {getNotification()}
        <Image className="cardImage" image={getImage(localFile)} alt={header} />
        <Title>{header}</Title>
        <Desc>
          {descriptionDish} <span>{weight}</span>
        </Desc>
        <Price>{getPrice()} грн.</Price>
      </WrapperLink>
      {!defaultPrice && (
        <TitleIngredients>Інгрідієнт на вибір:</TitleIngredients>
      )}
      {dropDownOptions}
      <WrapperAmount>
        <Amount>
          <ButtonAmount
            type="button"
            onClick={() => setAmount(+amount - 1)}
            disabled={amount < 2}
          >
            <Minus />
          </ButtonAmount>
          <Input
            name="amountDish"
            value={amount}
            onChange={({ target }) =>
              setAmount(target.value === '0' ? 1 : +target.value)
            }
            onBlur={({ target }) =>
              setAmount(target.value === '' ? 1 : +target.value)
            }
            maxLength={3}
          />
          <ButtonAmount
            type="button"
            onClick={() => setAmount(+amount + 1)}
            disabled={amount > 998}
          >
            <Plus />
          </ButtonAmount>
        </Amount>
        <Button variant="primary" type="button" onClick={onDelivery}>
          Замовити
        </Button>
      </WrapperAmount>
    </Card>
  );
}

CardDish.defaultProps = {
  animation: true,
};

CardDish.propTypes = {
  dish: PropTypes.shape({
    id: PropTypes.string.isRequired,
    posterId: PropTypes.string,
    header: PropTypes.string.isRequired,
    animationPhoto: PropTypes.shape({
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({
            images: PropTypes.shape({
              fallback: PropTypes.shape({ src: PropTypes.string }),
            }),
          }),
        }),
      }),
    }).isRequired,
    photo: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.shape({
      data: PropTypes.shape({ description: PropTypes.string.isRequired }),
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        posterId: PropTypes.string,
        price: PropTypes.number.isRequired,
        food_option: PropTypes.string.isRequired,
      }),
    ),
    isPremium: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    weight: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  delivery: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  setDishes: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  setNewTotalPrice: PropTypes.func.isRequired,
  amountDish: PropTypes.number.isRequired,
  setAmountDish: PropTypes.func.isRequired,
  animation: PropTypes.bool,
};
