import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const WrapperLink = styled(Link)`
  width: 100%;
  position: relative;
`;

export const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 75px;
  padding: 15px 0 12px 0;
  font-size: ${({ type }) => (type === 'premium' ? '12px' : '22px')};
  font-weight: ${({ type }) => (type === 'premium' ? '400' : 'bold')};
  border-radius: 50px;
  z-index: 5;
  color: ${({ type }) => (type === 'premium' ? '#000' : '#86b7d1')};
  background: ${({ type }) => (type === 'premium' ? '#86b7d1' : '#000')};
  text-transform: uppercase;

  @media (max-width: 1200px) {
    font-size: ${({ type }) => type === 'new' && '18px'};
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  color: #000;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 400px;
  }

  @media (max-width: 576px) {
    &:nth-child(1n) {
      margin-right: 0;
    }
    width: 100%;
    margin: 0 0 60px 0;
  }

  .flyNode {
    width: 150px;
    height: 150px;
    position: absolute;
    transform: scale(1.3);
    opacity: 1;
    transition: left 2s ease, top 2s ease, transform 2s ease, opacity 2s linear,
      width 2s linear, height 2s linear;
    z-index: 1000;
    background: url(${({ src }) => src}) center center;
    background-size: cover;
    filter: saturate(1.2);

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  height: 400px;
  filter: saturate(1.2);

  @media (max-width: 1200px) {
    height: 300px;
  }

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 576px) {
    width: auto;
  }
`;

export const Title = styled.h3`
  margin: 20px 0 8px 0;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.35;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  line-height: 1.55;
  font-weight: 400;
`;

export const Price = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.35;
  text-align: center;
  margin: 12px 0;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const TitleIngredients = styled.div`
  text-align: center;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 300;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

export const SelectOption = styled.select`
  min-width: 150px;
  border: 1px solid #ddd;
  background: #f8f8f8;
  color: #000;
  padding: 2px 30px 2px 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.55;
  font-weight: 300;
  margin-bottom: 20px;
  outline: none;
`;

export const WrapperAmount = styled.div`
  display: flex;
`;

export const Amount = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  border: 1px solid #777;
  font-size: 14px;
  border-radius: 30px;
  margin-right: 10px;
`;

export const Input = styled.input`
  width: 30px;
  height: 30px;
  text-align: center;
`;

export const ButtonAmount = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
