import styled from 'styled-components';

export const Dishes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  padding-top: 40px;

  @media (max-width: 960px) {
    justify-content: space-evenly;
    align-items: start;
  }

  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 10px;
  justify-content: flex-end;
  gap: 5px;

  @media (max-width: 1025px) {
    justify-content: center;
  }

  span {
    margin-right: 10px;
    font-weight: bold;
    color: #333;
    font-size: 16px;
    line-height: 1.33;
    font-family: 'Josefin Sans', sans-serif;
  }

  select {
    padding: 5px;
    border: 1px solid #ccc; /* Цвет границы */
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.33;
    color: #333; /* Цвет текста */
    font-family: 'Josefin Sans', sans-serif;

    option {
      animation: slideDown 1s ease;

      @keyframes slideDown {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    cursor: pointer;

    /* Стили при наведении */
    &:hover {
      border-color: #666; /* Цвет границы при наведении */
      background-color: #fff;
    }
  }
`;
