import * as React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layout';
import Seo from '../seo';
import CardDish from '../CardDish';
import Container from '../Container';
import { Dishes, SortContainer } from './styled';
import useBasket from '../../hooks/useBasket';

export default function PageDish({
  titleHeader,
  description,
  descriptionMeta,
  title,
  dishes,
}) {
  const {
    delivery,
    totalPrice,
    amount,
    setNewDelivery,
    setNewTotalPrice,
    setNewAmount,
  } = useBasket();
  const [sortBy, setSortBy] = React.useState('');
  const [showFilters, setShowFilters] = React.useState(true);

  const sortDishesByPriceAscending = () => {
    return [...dishes].sort((a, b) => {
      const priceA =
        a.price !== null
          ? a.price
          : a.options.length > 0
          ? a.options[0].price
          : Infinity;
      const priceB =
        b.price !== null
          ? b.price
          : b.options.length > 0
          ? b.options[0].price
          : Infinity;

      return priceA - priceB;
    });
  };

  const sortDishesByPriceDescending = () => {
    return [...dishes].sort((a, b) => {
      const priceA =
        a.price !== null
          ? a.price
          : a.options.length > 0
          ? a.options[0].price
          : Infinity;

      const priceB =
        b.price !== null
          ? b.price
          : b.options.length > 0
          ? b.options[0].price
          : Infinity;
      return priceB - priceA;
    });
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortBy(value);
  };

  const renderSortedDishes = () => {
    let filteredDishes = [...dishes];

    if (sortBy === 'priceAscending') {
      filteredDishes = sortDishesByPriceAscending();
    } else if (sortBy === 'priceDescending') {
      filteredDishes = sortDishesByPriceDescending();
    }

    return filteredDishes.map((dish) => (
      <CardDish
        key={dish.id}
        dish={dish}
        delivery={delivery}
        setDishes={setNewDelivery}
        totalPrice={totalPrice}
        setNewTotalPrice={setNewTotalPrice}
        amountDish={amount}
        setAmountDish={setNewAmount}
      />
    ));
  };

  return (
    <Layout titleHeader={titleHeader} description={description}>
      <Seo title={title} description={descriptionMeta} />
      <Container>
        {showFilters && (
          <SortContainer>
            <span>Сортування </span>
            <select
              value={sortBy}
              onChange={handleSortChange}
              className="animated-select"
            >
              <option value="" className="option">
                Без сортування
              </option>
              <option value="priceAscending" className="option">
                Від найдешевших
              </option>
              <option value="priceDescending" className="option">
                Від найдорощих
              </option>
            </select>
          </SortContainer>
        )}

        <Dishes>{renderSortedDishes()}</Dishes>
      </Container>
    </Layout>
  );
}

PageDish.defaultProps = {
  description: null,
  descriptionMeta: ``,
};

PageDish.propTypes = {
  titleHeader: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionMeta: PropTypes.string,
  title: PropTypes.string.isRequired,
  dishes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      photo: PropTypes.object.isRequired,
      price: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      description: PropTypes.shape({
        data: PropTypes.shape({ description: PropTypes.string.isRequired }),
      }),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
          food_option: PropTypes.string.isRequired,
        }),
      ),
      isPremium: PropTypes.bool.isRequired,
      isNew: PropTypes.bool.isRequired,
      weight: PropTypes.string.isRequired,
      ingredients: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};
